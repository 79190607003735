import { useIntl } from 'react-intl'

import { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {
  fetchSubventionClaim,
  getSubventionRequestDetails,
} from '@mzaednica/core'
import withAuthentication from '../../utils/withAuthentication'

const SubventionRequest = ({
  fetchSubventionClaim,
  subventionRequestDetails,
}) => {
  let { id } = useParams()

  const { subventionRequestData } = subventionRequestDetails

  const getInitialData = useCallback(async () => {
    await fetchSubventionClaim(id)
  }, [fetchSubventionClaim, id])

  useEffect(() => {
    getInitialData()
  }, [getInitialData])

  const { formatMessage } = useIntl()
  return (
    <>
      <Header />
      <section className="bg-lightGray4 py-12">
        <h2 className="text-xl md:text-2xl font-semibold text-lightGray5 text-center">
          {formatMessage({ id: 'inquiry' })}
        </h2>
        <div className="container px-4 md:px-0 pt-10">
          {subventionRequestData &&
            subventionRequestData.fields.map((fld) => {
              switch (fld.__component) {
                case 'fields.text-field':
                case 'fields.rich-text-field':
                  return (
                    <label className="grid md:grid-cols-2 border-b border-lightGray3 py-3 md:py-5">
                      <span className="text-darkGray1 text-lg md:text-xl font-semibold w-auto">
                        {fld.fieldLabel}
                        {fld.isRequired ? '*' : ''}
                      </span>
                      <div className="inline-flex items-center justify-between">
                        {fld.value}
                      </div>
                    </label>
                  )
                case 'fields.media-field':
                  return (
                    <div>
                      {fld.fieldLabel}
                      <img
                        alt="someAlt"
                        className="h-64"
                        src={`${process.env.RAZZLE_API_URL}${fld.value.url}`}
                      />
                    </div>
                  )
                case 'fields.multiple-media-field':
                  // eslint-disable-next-line no-case-declarations
                  const component = fld.value
                    ? fld.value.map((val) => (
                        <>
                          {val.mime.startsWith('image/') ? (
                            <a href={`${process.env.RAZZLE_API_URL}${val.url}`}>
                              <img
                                alt="someAlt"
                                className="w-20 h-20"
                                src={`${process.env.RAZZLE_API_URL}${val.url}`}
                              />
                            </a>
                          ) : (
                            <>
                              <a
                                className="text-blue"
                                href={`${process.env.RAZZLE_API_URL}${val.url}`}
                              >{`${val.name}`}</a>
                              <a
                                href={`${process.env.RAZZLE_API_URL}${val.url}`}
                                download
                                target="_blank"
                                className="text-blue ml-10"
                                rel="noreferrer"
                              >
                                {formatMessage({ id: 'downloadDoc' })}
                              </a>
                            </>
                          )}
                          <br />
                        </>
                      ))
                    : ''
                  return (
                    <div>
                      {fld.fieldLabel}
                      {component}
                    </div>
                  )
              }
            })}
          <div>{formatMessage({ id: 'iAgreeMuniCopyIDPersonalData' })}</div>
        </div>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  subventionRequestDetails: getSubventionRequestDetails(state),
})

const mapDispatchToProps = {
  fetchSubventionClaim,
}

export default withAuthentication(
  connect(mapStateToProps, mapDispatchToProps)(SubventionRequest),
)
