import { Fragment, useState } from 'react'
import * as yup from 'yup'
import { Formik, ErrorMessage, Form, Field } from 'formik'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { useIntl } from 'react-intl'

import WithConnectedModal from '../../utils/withConnectedModal'
import { SIGNUP_MODAL_ID } from '../../consts/modalIds'
import { register, openModal } from '@mzaednica/core'

const SignupSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Невалидна е-маил адреса')
    .required('Ова поле е задолжително'),
  password: yup
    .string()
    .min(5, 'Внесете најмалку 5 знаци')
    .required('Ова поле е задолжително'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Лозинките мора да се идентични'),
})

const startValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
}

const SignupModal = ({ isOpen = false, closeModal, register }) => {
  const history = useHistory()
  const [initialValues] = useState(startValues)
  const [errMessage, setErrMessage] = useState(null)
  const { formatMessage } = useIntl()

  const registerUser = async (values) => {
    try {
      await register({ ...values, username: values.email })
      closeModal()
      history.push('/completeProfile')
    } catch (e) {
      const errMessage = e.response.data.data[0].messages[0].message
      setErrMessage(errMessage)
      throw e
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={closeModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-modal bg-bottom bg-no-repeat bg-cover rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-5 sm:px-0">
                <button
                  type="button"
                  className="focus:outline-none focus:ring-0 absolute right-0 text-white p-3"
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col items-center justify-center sm:py-24 py-10 space-y-4 px-8 sm:px-0">
                <Formik
                  initialValues={initialValues}
                  validationSchema={SignupSchema}
                  validateOnChange={false}
                  enableReinitialize={true}
                  onSubmit={registerUser}
                >
                  {({ isSubmitting }) => (
                    <Form className="" autoComplete="on">
                      <label className="text-sm text-left">
                        {formatMessage({ id: 'email' })}
                      </label>
                      <Field
                        name="email"
                        id="email"
                        placeholder={formatMessage({ id: 'enterEmail' })}
                        className="p-2 bg-whiteSmoke mb-4 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
                      />
                      <div className="text-red text-center mb-2">
                        <ErrorMessage name="email" />
                      </div>
                      <label className="text-sm text-left">
                        {formatMessage({ id: 'pass' })}
                      </label>
                      <Field
                        type="password"
                        name="password"
                        placeholder={formatMessage({ id: 'enterPass' })}
                        className="p-2 bg-whiteSmoke mb-4 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
                      />
                      <div className="text-red text-center mb-2">
                        <ErrorMessage name="password" />
                      </div>
                      <label className="text-sm text-left">
                        {formatMessage({ id: 'passRepeat' })}
                      </label>
                      <Field
                        type="password"
                        name="passwordConfirmation"
                        placeholder={formatMessage({ id: 'passRepeat' })}
                        className="p-2 bg-whiteSmoke mb-4 mt-2 rounded-md border-1 border-lightBlue inline-flex w-full focus:outline-none"
                      />
                      <div className="text-red text-center mb-2">
                        <ErrorMessage name="passwordConfirmation" />
                      </div>
                      <div className="mt-6 text-left flex w-full">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="bg-lightBlue px-10 py-2 text-white font-bold rounded-md border-2 border-lightBlue text-center focus:outline-none text-sm md:text-base w-full"
                        >
                          {formatMessage({ id: 'register' })}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                {errMessage && (
                  <div className="text-red text-center mb-2">
                    {formatMessage({ id: errMessage })}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

const mapDispatchToProps = {
  register,
  openModal,
}

export default WithConnectedModal(
  SIGNUP_MODAL_ID,
  connect(null, mapDispatchToProps)(SignupModal),
)
