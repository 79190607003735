import { useParams } from 'react-router-dom/cjs/react-router-dom'
import {
  fetchSurvey,
  getSurveyDetails,
  getLanguage,
  surveyResponses,
  updateResponses,
} from '@mzaednica/core'
import { connect } from 'react-redux'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import findImageWithQuality from '../../utils/findImageWithQuality'
import { Formik, Form, Field } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { LANGUAGES } from '../../i18n/settings/languages'
import { useIntl } from 'react-intl'
import { SurveySchema } from './validation'
import defaultImage from '../../img/app_icon.png'
import { TranslatedErrorMessage } from '../../components/TranslatedErrorMessage'
import { AlertDialog } from '../../components/AlertDialog'
import { constructAbsoluteAppUrl } from '../../utils/constructAbsoluteAppUrl'

const SurveyAnswerPage = ({
  fetchSurvey,
  surveyDetails,
  language,
  createSurveyResponses,
  updateSurveyResponses,
}) => {
  let { id } = useParams()
  const { surveyData } = surveyDetails
  const formRef = useRef()
  const [initialValues, setInitialValues] = useState()
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (!surveyData || `${surveyData.id}` !== id) {
      fetchSurvey(id)
    }
  }, [fetchSurvey, id, surveyData])

  const { formatMessage } = useIntl()

  useEffect(() => {
    fetchSurvey(id)
  }, [fetchSurvey, id])

  useEffect(() => {
    if (id && surveyData) {
      setInitialValues({
        questions: surveyData?.questions?.map((question) => ({
          type: question.type,
          survey: surveyData?.id,
          question: question?.id,
          options: question?.options.reduce((acc, cur) => {
            if (cur?.isAnswered) {
              acc.push(cur.id)
            }
            return acc
          }, []),
          input_response: question?.input_response ?? '',
        })),
      })
    }
  }, [id, surveyData])

  const handleSubmit = async (values) => {
    const submitData = values?.questions.reduce(
      (acc, cur) => [
        ...acc,
        {
          survey: cur.survey,
          question: cur.question,
          options: cur.options,
          input_response: cur.input_response,
        },
      ],
      [],
    )
    try {
      if (surveyData?.answered) {
        await updateSurveyResponses(submitData)
      } else {
        await createSurveyResponses(submitData)
      }

      setShowPopup(true)
    } catch (e) {
      console.error('Failed to create survey responses')
    }
  }

  return (
    <>
      <Header />
      <AlertDialog
        title={formatMessage({ id: 'surveyParticipateSuccess' })}
        description={formatMessage({ id: 'changeSurveyResponses' })}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      />
      <div className="container mt-5 mb-20 max-w-3xl flex flex-col justify-center">
        <div>
          {surveyData?.image ? (
            <div className="relative w-full h-64 overflow-hidden flex justify-center items-center mb-10">
              <img
                className="absolute inset-0 object-cover h-full w-full"
                src={constructAbsoluteAppUrl(
                  findImageWithQuality(surveyData.image, 'medium'),
                )}
                alt=""
              />
            </div>
          ) : (
            <div className="relative w-full h-64 overflow-hidden flex justify-center items-center mb-10">
              <img
                className="absolute inset-0 object-cover h-full w-full"
                src={defaultImage}
                alt=""
              />
            </div>
          )}
        </div>
        <div className="m-5 p-5">
          <Formik
            initialValues={initialValues}
            validationSchema={SurveySchema}
            validateOnChange
            enableReinitialize={true}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ values }) => (
              <Form name="questions">
                {surveyData?.questions?.map((question, index) => (
                  <div
                    key={index}
                    className="border-b border-#B4B4B4 h-15 my-5"
                  >
                    <div className="font-semibold">
                      {language === LANGUAGES.MK
                        ? formatMessage({ id: 'question' })
                        : formatMessage({ id: 'question' })}
                      {index + 1}*
                    </div>
                    <div className="font-semibold mb-5">
                      {language === LANGUAGES.MK
                        ? question?.text
                        : question?.text_sq}
                    </div>
                    <div key={index}>
                      {question?.type === 'single_choice' && (
                        <div className="m-5" role="group">
                          {question?.options?.map((option, i) => (
                            <label
                              key={i}
                              className="flex flex-start items-center"
                            >
                              <Field
                                type="radio"
                                name={`questions.${index}.options`}
                                value={option.id}
                                className="mr-2 items-center"
                                checked={values?.questions[
                                  index
                                ]?.options?.includes(option?.id)}
                                onChange={(e) => {
                                  const selectedValue = Number(e.target.value)
                                  formRef.current.setFieldValue(
                                    `questions.${index}.options`,
                                    [selectedValue],
                                  )
                                }}
                              />
                              {option.text}
                            </label>
                          ))}
                          <TranslatedErrorMessage
                            name={`questions.${index}.options`}
                          />
                        </div>
                      )}
                      {question?.type === 'multiple_choice' && (
                        <div className="m-5">
                          {question?.options?.map((option, i) => (
                            <label
                              key={i}
                              className="flex flex-start items-center"
                            >
                              <Field
                                type="checkbox"
                                name={`questions.${index}.options`}
                                value={option.id}
                                className="mr-2 items-center"
                                onChange={(e) => {
                                  const isChecked = e.target.checked
                                  const selectedArray =
                                    values.questions[index].options || []
                                  const selectedValue = Number(option.id)

                                  if (isChecked) {
                                    formRef.current.setFieldValue(
                                      `questions.${index}.options`,
                                      [...selectedArray, selectedValue],
                                    )
                                  } else {
                                    formRef.current.setFieldValue(
                                      `questions.${index}.options`,
                                      selectedArray.filter(
                                        (id) => id !== selectedValue,
                                      ),
                                    )
                                  }
                                }}
                              />
                              {option.text}
                            </label>
                          ))}
                          <TranslatedErrorMessage
                            name={`questions.${index}.options`}
                          />
                        </div>
                      )}
                      {question?.type === 'input' && (
                        <div className="m-5">
                          <Field
                            name={`questions.${index}.input_response`}
                            as="textarea"
                            placeholder={formatMessage({
                              id: 'questionAnswer',
                            })}
                            className="w-full"
                          />
                          <TranslatedErrorMessage
                            name={`questions.${index}.input_response`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="w-full flex justify-center">
                  <button
                    type="submit"
                    className="relative bg-nav bg-left-top bg-no-repeat bg-cover z-10 p-2 text-md font-semibold text-white rounded-full max-w-xs mt-8 self-center"
                  >
                    {formatMessage({ id: 'sent' })}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({
  surveyDetails: getSurveyDetails(state),
  language: getLanguage(state),
})

const mapDispatchToProps = {
  fetchSurvey,
  createSurveyResponses: surveyResponses,
  updateSurveyResponses: updateResponses,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAnswerPage)
