import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  listCategories,
  listInterests,
  getProfile,
  listMunicipalities,
} from '@mzaednica/core'
import LoginModal from './components/Modals/LoginModal'
import EmailLoginModal from './components/Modals/EmailLoginModal'
import ForgotPasswordModal from './components/Modals/ForgotPasswordModal'
import SignupModal from './components/Modals/SignupModal'
import { FacebookProvider } from 'react-facebook'

const Layout = ({
  getProfile,
  listMunicipalities,
  listInterests,
  listCategories,
  children,
}) => {
  const history = useHistory()

  useEffect(() => {
    const fetch = async () => {
      let response = null
      try {
        response = await getProfile()
        if (response && !response.profileComplete) {
          history.push('/completeProfile')
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [getProfile, history])

  useEffect(() => {
    listMunicipalities()
    listInterests()
    listCategories()
  })

  return (
    <>
      <FacebookProvider appId={process.env.RAZZLE_FACEBOOK_ID}>
        {children}
        <LoginModal />
        <EmailLoginModal></EmailLoginModal>
        <ForgotPasswordModal></ForgotPasswordModal>
        <SignupModal />
      </FacebookProvider>
    </>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  getProfile,
  listMunicipalities,
  listInterests,
  listCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
