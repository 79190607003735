import { useIntl } from 'react-intl'

import moment from 'moment'
import ProcessBlue from '../../img/process_blue.svg'
import FinishedBlue from '../../img/finished_blue.svg'
import avatar from '../../img/nophoto.jpg'
import { Link } from 'react-router-dom'
import findImageWithQuality from '../../utils/findImageWithQuality'
import { LANGUAGES } from '../../i18n/settings/languages'
import { getLanguage } from '@mzaednica/core'
import withAuthentication from 'utils/withAuthentication'
import { connect } from 'react-redux'
import defaultImage from '../../img/app_icon.png'
import { constructAbsoluteAppUrl } from '../../utils/constructAbsoluteAppUrl'

const SurveyCard = ({ survey, language }) => {
  const { id, title, title_sq, image, created_at, municipality, active } =
    survey

  const { formatMessage } = useIntl()

  return (
    <>
      <Link
        to={`/surveyDetails/${id}`}
        className="block max-w-xl w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex ">
          <div className="mr-3.5 flex-shrink-0">
            <img
              src={constructAbsoluteAppUrl(
                findImageWithQuality(municipality.logo),
              )}
              alt="Logo"
              className="border-4 border-blue rounded-full w-10 h-10 md:w-16 md:h-16"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h5 className="text-lg font-semibold">
              {formatMessage({ id: 'municipality' })}{' '}
              {language === LANGUAGES.MK
                ? municipality.name
                : municipality.name_sq}
            </h5>
            <p className="text-darkGray1 text-base align-middle">
              {moment(created_at).format('DD.MM.YYYY')}
              {active ? (
                <img
                  src={ProcessBlue}
                  alt=""
                  className="w-5 h-5 inline-block align-middle ml-4"
                />
              ) : (
                <img
                  src={FinishedBlue}
                  alt=""
                  className="w-5 h-5 inline-block align-middle ml-4"
                />
              )}
              <span className="text-blue font-semibold ml-1">
                {active
                  ? formatMessage({ id: 'active2' })
                  : formatMessage({ id: 'unactive' })}
              </span>
            </p>
          </div>
        </div>
        <h4 className="text-sm md:text-base font-semibold uppercase my-2 w-full">
          {language === LANGUAGES.MK ? title : title_sq ?? title}
        </h4>
        {image ? (
          <img
            className="h-52 w-full object-cover"
            src={
              !image
                ? avatar
                : constructAbsoluteAppUrl(findImageWithQuality(image, 'small'))
            }
            alt=""
          />
        ) : (
          <img className="h-52 w-full object-cover" src={defaultImage} alt="" />
        )}
      </Link>
    </>
  )
}
const mapStateToProps = (state) => ({
  language: getLanguage(state),
})

export default withAuthentication(connect(mapStateToProps, {})(SurveyCard))
