import ProfileIcon from '../img/profile_menu_gray.svg'
import CardIcon from '../img/card.svg'
import Header from './Header'
import { useIntl } from 'react-intl'

const ProfileMenu = ({ userDetails }) => {
  let user = userDetails.userData
  const { formatMessage } = useIntl()

  return (
    <>
      <Header />
      <div className="bg-green flex flex-col items-center pt-10 pb-8">
        {user && (
          <>
            <figure>
              <img
                src={`${
                  user.profilePicture
                    ? `${process.env.RAZZLE_API_URL}/${user.profilePicture.url}`
                    : ProfileIcon
                }`}
                alt="Profile icon"
                width="183"
                height="auto"
                className="w-16 h-16 border-4 border-orange rounded-full"
              />
            </figure>

            <h2 className="text-white text-2xl font-semibold">
              {user.firstName + ' ' + user.familyName}
            </h2>
            <h3 className="text-white text-lg">
              {formatMessage({ id: 'municipality' })}{' '}
              {user.municipality ? user.municipality.name : ''}
            </h3>
            <div className="flex items-center my-5">
              {!user?.municipality?.hasCard ? (
                ''
              ) : (
                <>
                  <img alt="someAlt" src={CardIcon} width="48" height="auto" />
                  {user.cardNumber === null || user.cardNumber === '' ? (
                    <a
                      href="https://mojkarpos.mk/registracija/"
                      target="_blank"
                      className="text-white text-base underline ml-3"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'myKarposh' })}
                    </a>
                  ) : (
                    <div className="text-white ml-5">#{user.cardNumber}</div>
                  )}
                </>
              )}
            </div>
            <div className="flex items-center justify-center space-x-5 flex-wrap">
              {user.interests.map((item) => (
                <div
                  key={item.id}
                  className="w-auto inline-flex items-center justify-center border-2 border-white text-sm md:text-base font-semibold rounded-full text-white bg-transparent hover:bg-opacity-80 py-1.5 px-5 mb-4"
                >
                  {item.name}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ProfileMenu
