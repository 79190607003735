import { Link } from 'react-router-dom'
import moment from 'moment'
import { useIntl } from 'react-intl'

import avatar from '../../img/nophoto.jpg'
import { LANGUAGES } from '../../i18n/settings/languages'

const NewsCard = ({ news, language }) => {
  const {
    id,
    title,
    title_sq,
    featuredImage,
    municipality,
    interest,
    created_at,
  } = news

  const { formatMessage } = useIntl()

  return (
    <>
      <Link
        to={`/newsDetails/${id}`}
        className="bg-white max-w-md w-full px-4 py-18 rounded-xl shadow-card"
      >
        <div className="flex">
          <div className="mr-3.5 flex-shrink-0">
            <img
              src={`${process.env.RAZZLE_API_URL}${municipality.logo.url}`}
              alt="Municipality logo"
              className="w-16 h-16 border-4 border-orange rounded-full"
            />
          </div>
          <div className="flex flex-col justify-center">
            <h5 className="text-lg font-semibold">
              {formatMessage({ id: 'municipality' })}{' '}
              {language === LANGUAGES.MK
                ? municipality.name
                : municipality.name_sq}
            </h5>
            <p className="text-darkGray1 text-base align-middle">
              {moment(created_at).format('DD.MM.YYYY')}
            </p>
          </div>
        </div>
        <h4 className="text-base font-bold uppercase my-2 overflow-ellipsis whitespace-nowrap overflow-hidden">
          {language === LANGUAGES.MK ? title : title_sq ?? title}
        </h4>
        <div className="mb-2">
          {interest
            ? language === LANGUAGES.MK
              ? interest.name
              : interest.name_sq ?? interest.name
            : ''}
        </div>
        {featuredImage && (
          <img
            className="h-52 w-full object-cover"
            src={
              featuredImage.length === 0
                ? avatar
                : `${process.env.RAZZLE_API_URL}${featuredImage.url}`
            }
            alt=""
          />
        )}
      </Link>
    </>
  )
}

export default NewsCard
